import AbstractAssert from './../AbstractAssert';

const DIC_INVALID = 'Please complete the CAPTCHA.';

export default class ReCaptchaAssert extends AbstractAssert {
    /**
     * @param {String} value
     * @param {Boolean} required
     *
     * @returns {string[]}
     */
    validate ( value, required ) {
        required = typeof required === 'function' ? required() : required;
        value = value.replace(/^([^:]+::)/, '');

        if (required && (typeof value !== 'string' || value.length === 0)) {
            return [DIC_INVALID];
        }

        return typeof value === 'string' ? [] : [DIC_INVALID];
    }
}
