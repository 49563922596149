import {NotBlank} from 'constraint-validator';

const MESSAGE_INVALID = 'Please complete the CAPTCHA.';

export default class Captcha extends NotBlank {
    /**
     * @param {{active: boolean, message: string, allow_null: boolean}} [options]
     */
    constructor(options) {
        super(options);
    }

    /**
     * @return {{message: string}}
     */
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            ...{
                'active': true,
                'message': MESSAGE_INVALID,
            }
        };
    }

    /**
     * Test provided value and return Error if occurs
     *
     * @param value
     *
     * @return {Error|undefined}
     */
    validate(value, options) {
        const active = typeof this.options.active === 'function' ? this.options.active() : this.options.active;

        value = value.replace(/^([^:]+::)/, '');

        if (active) {
            return super.validate(value, options);
        }
    }
}
