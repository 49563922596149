import AbstractView from './AbstractView';

const SELECTOR_SEARCH_INPUT = 'input[name="q"]';

export default class GlobalSearchView extends AbstractView {
    events () {
        return {
            'click [for="menu-search"]': 'doFocus'
        };
    }

    initialize () {
        console.log(this.constructor.name + '::initialize');
    }

    doFocus() {
        const inputEl = this.el.querySelector(SELECTOR_SEARCH_INPUT);
        if (inputEl) {
            setTimeout(() => {
                inputEl.focus();
            }, 150);
        }
    }
}
