import Backbone from 'Lib/Backbone';

export default class AbstractRouter extends Backbone.Router {
    initialize () {
        console.log(this.constructor.name + '::initialize');
    }

    startHistory ( options ) {
        if (!Backbone.History.started) {
            Backbone.history.start(options);
        }
    }

    loadFragment () {
        Backbone.history.loadUrl(Backbone.history.fragment);
    }

    /**
     * @param {String} key
     * @param {String|[String]} value
     * @returns {boolean}
     */
    setQueryParam ( key, value ) {
        const url = new URL(window.location);
        const hasKey = url.searchParams.has(key);

        this.removePageParam(url);
        this.fixKeys(url, key);

        if (!hasKey || hasKey && url.searchParams.get(key) !== value) {
            if (Array.isArray(value)) {
                value.forEach((v, idx) => {

                    if (idx > 0) {
                        url.searchParams.append(`${key}[]`,v);
                    } else {
                        url.searchParams.set(`${key}[]`,v);
                    }
                });
            } else {
                url.searchParams.set(key, value);
            }

            window.location = url.toString();

            return true;
        }

        return false;
    }

    /**
     * @param {String} key
     * @returns {boolean}
     */
    removeQueryParam(key, target) {
        const url = new URL(window.location);

        this.removePageParam(url);
        this.fixKeys(url, key);

        if (url.searchParams.has(key) || url.searchParams.has(`${key}[]`)) {
            if (target) {
                const values = url.searchParams.getAll(`${key}[]`);
                const index = values.indexOf(target);

                if (index >= 0 && values.length > 1) {
                    values.splice(index, 1);
                    return this.setQueryParam(key, values);
                }
            }

            url.searchParams.delete(key);
            url.searchParams.delete(`${key}[]`);

            window.location = url.toString();

            return true;
        }

        return false;
    }

    removePageParam(url)
    {
        if (url.searchParams.has('page')) {
            url.searchParams.delete('page');
        }
    }

    fixKeys(url, key) {
        // this will handle only cases like this param[0], param[1]
        const toPatch = [];

        for(let pair of url.searchParams.entries()) {
            const p = new RegExp(`^${key}(\\[\\d+\\])$`);
            if (!p.test(pair[0])) {
                continue;
            }

            toPatch.push({key: pair[0], value: pair[1]});
        }

        if (toPatch.length === 0) {
            return false;
        }

        toPatch.forEach(pair => {
            // remove invalid key
            url.searchParams.delete(pair.key);

            if (url.searchParams.has(`${key}[]`)) {
                url.searchParams.append(`${key}[]`, pair.value);
            } else {
                url.searchParams.set(`${key}[]`, pair.value);
            }
        });

        return true;
    }
}

