import AbstractView  from './AbstractView';

const CLASS_WRAP = 'loader__wrp';
const LOADER_TPL = require('@component/loader-overlay.twig');

export default class LoaderOverlayView extends AbstractView {
    initialize ( options ) {
        console.log(this.constructor.name + '::initialize');

        this.options = {
            ...{
                wrap: false,
                color: 'dark'
            },
            ...options,
        };

        this.tpl = LOADER_TPL;
        this.container = this.el;

        this.render();
    }

    async render() {
        const div = document.createElement('div');
        div.innerHTML = await this.tpl({color: this.options.color});

        if (this.options.wrap) {
            this.container.classList.add(CLASS_WRAP);
        }

        this.el = div.firstChild;
        this.container.appendChild(this.el);
    }

    remove() {
        this.container.classList.remove(CLASS_WRAP);
        super.remove();
    }
}
