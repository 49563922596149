import GlobalConfig from '@utils/GlobalConfig';
import {Utils} from '@cryengine/styleguide';
import Manifest from './Manifest';
import routeGenerate from './Routing';
import IDOM from './IDOM';
import Twig from 'twig';

const number_format = require('./Functions/number_format');

const Patcher = new IDOM();
const Assets = new Manifest();
const Config = GlobalConfig.getInstance();

const translate = ( text, options ) => {
    // https://github.com/willdurand/BazingaJsTranslationBundle/blob/master/Resources/doc/index.md
    options = {
        ...{},
        ...options,
    };

    delete options['_keys'];

    Object.keys(options).forEach(( key ) => {
        text = text.replace(new RegExp(key, 'g'), options[key]);
    });

    return text;
};

Twig.extendFunction('asset', async ( name ) => {
    return await Assets.asset(name);
});

Twig.extendFilter('trans', ( text, options ) => {
    options = Array.isArray(options) && options.length > 0 ? options[0] : {};

    return translate(text, options);
});

/**
 * Process URL generation in twig
 */
Twig.extendFunction('url', async ( url, options ) => {
    return await routeGenerate(url, options);
});

Twig.extendFunction('path', async ( url, options ) => {
    return await routeGenerate(url, options);
});

Twig.extendFunction('global_g_key', async () => {
    return Config.get('global_g_key');
});

Twig.extendFunction('global_m_key', async () => {
    return Config.get('global_m_key');
});

Twig.extendFunction('global_emails', async () => {
    return Config.get('cryengine.emails');
});

Twig.extendFunction('global_currencies', async () => {
    return Config.get('global_currencies');
});

Twig.extendFunction('global_g_track', async () => {
    return Config.get('global_g_track');
});

Twig.extendFilter('route_hash', ( params ) => {
    const result = [];

    Object.keys(params).forEach(( key ) => {
        // TwigJS is passing "_keys" field in params that should not be processed
        if (key !== '_keys') {
            result.push([key, params[key]].join('/'));
        }
    });

    return result.join('/');
});

Twig.extendFilter('size', ( params ) => {
    return Utils.formatters.formatSize(params);
});

Twig.extendFilter('price', ( value, params ) => {
    const currency = typeof params[0] === 'undefined' ? Config.get('global_currency').label : params[0].toUpperCase();
    const decimals = typeof params[1] === 'undefined' ? 2 : parseInt(params[1]);

    const formatter = new Intl.NumberFormat(
        'de-DE',
        {style: 'currency', currency: currency},
    );

    let symbol = formatter.format(0);
    symbol = symbol
        .replace(/[.|,]/g, '')
        .replace(/[0-9]/g, '')
        .trim();

    switch (currency) {
        case 'EUR':
            value = number_format(value, decimals, ',', '') + ' ' + symbol;
            break;

        case 'USD':
        case 'GBP':
            value = symbol + number_format(value, decimals, '.', '');
            break;

        default:
            throw new Error(`There is no configuration for ${currency} currency`);
    }

    return value;
});

Twig.Patcher = Patcher;

export default Twig;
