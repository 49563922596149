import AbstractModel from './../AbstractModel';

export default class PrivacyPreferencesModel extends AbstractModel {
    url () {
        return '/api/user/privacy-preferences';
    }

    defaults () {
        return {
            'basic': null,
            'analytics': null,
            'youtube': null,
            'soundcloud': null,
            'sketchfab': null,
            'csrf_token': '',
        };
    }
}
