import slugify from 'slugify';

/**
 * @param {string} text
 * @return {string}
 */
export default function alias(text) {
    return slugify(text)
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
}
