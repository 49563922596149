const Backbone = require('exoskeleton');

Backbone.View = require('./Backbone/NativeView');
const fAjax = require('backbone.fetch/backbone.fetch');

/**
 * By default fetch/ajax does not send credentials
 *
 * @param options
 * @return {*}
 */
Backbone.ajax = function(options) {
    if (typeof options === 'object') {
        options.credentials = 'include';
    }

    return fAjax(options);
};

if (!window.mediator) {
    window.mediator = {};
    Object.assign(window.mediator, Backbone.Events);
}

export default Backbone;
