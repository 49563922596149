import AbstractFormView  from './AbstractFormView';
import NewsletterModel   from '../../Model/Form/NewsletterModel';
import LoaderOverlayView from './../LoaderOverlayView';

const TEMPLATE_FORM = require('@forms/newsletter-form.twig');

/**
 * Newsletter registration form
 */
export default class NewsletterForm extends AbstractFormView {
    events () {
        this.isUserLoggedIn = this.el.dataset.isUserLoggedIn === '1';
        const options = {
            newsletter_privacy: {
                required: !this.isUserLoggedIn
            }
        };
        return {
            ...this.bindModelView(new NewsletterModel({}, options)),
            ...{},
        };
    }

    initialize ( options ) {
        super.initialize({...options, ...{resetOnSuccess: true}});
        this.tpl = TEMPLATE_FORM;
    }

    beforeSubmit () {
        super.beforeSubmit();
        this.overlay = new LoaderOverlayView({el: this.el});
    }

    async render(data) {
        typeof this.overlay !== 'undefined' && this.overlay.remove();
        data = {
            ...data,
            ...{isUserLoggedIn: this.isUserLoggedIn},
        };
        await super.render(data);
    }
}
