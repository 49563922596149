import Backbone from 'Lib/Backbone';
import Twig from 'Lib/Twig';

export default class AbstractView extends Backbone.View {
    constructor(options = {}) {
        super(options);

        if (options && options.tpl) {
            this.tpl = options.tpl;
        }
    }

    async template ( data ) {
        if (!this.tpl) {
            throw new Error('Template is undefined.');
        }

        return this.tpl(data);
    }

    async render ( data ) {
        try {
            const html = await this.template(data);

            if (!this.vnode && !this.el.hasChildNodes()) {
                // render new component
                this.el.innerHTML = '<div></div>';
                this.el = this.el.firstChild;
            }

            const el = this.vnode ? this.vnode : this.el;

            this.vnode = Twig.Patcher.patch(el, html);
            this.setElement(this.vnode.elm);

            if (this.el) {
                // ГОСТ Р 58281-2018
                const selectEl = this.el.querySelector('select');
                if (selectEl) {
                    const selectValue = selectEl.querySelector('option[selected]');

                    if (selectValue) {
                        selectEl.value = selectValue.value;
                    }
                }

                const dateEl = this.el.querySelector('[data-feature="datepicker"]');
                if (dateEl) {
                    dateEl.value = dateEl.getAttribute('value');
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    getNowDate() {
        const now = new Date();
        now.setMinutes(0);
        now.setHours(0);
        now.setSeconds(0);
        now.setMilliseconds(0);

        return now;
    }

    /**
     * Fix broken behavior
     *
     * @param element
     * @private
     */
    _setElement(element) {
        const paddedLt = /^\s*</;

        if (typeof element == 'string') {
            if (paddedLt.test(element)) {
                const el = document.createElement('div');
                el.innerHTML = element;
                this.el = el.firstChild;
            } else {
                this.el = document.querySelector(element);
            }
        } else if (element && element.length && Array.isArray(element)) {
            this.el = element[0];
        } else {
            this.el = element;
        }
    }
}
