import AbstractModel from './../AbstractModel';
import {IsTrue} from 'constraint-validator';

export default class LicenseModel extends AbstractModel {
    url() {
        return '/api/user/license/accept';
    }

    defaults() {
        return {
            'tos': '',
            'csrf_token': '',
        };
    }

    initialize(attributes, options) {
        // @TODO: remove this check once all forms switched to new validator
        options = {...options, ...{validator: {}}};
        super.initialize(attributes, options);

        this.form
            .add('tos', [
                new IsTrue({
                    message: 'You need to agree to the CRYENGINE License Agreement.'
                })
            ]);
    }
}
