import AbstractModel from './../AbstractModel';
import {NotBlank, Length, Email, IsTrue} from 'constraint-validator';

export default class NewsletterModel extends AbstractModel {
    url () {
        return '/api/newsletter';
    }

    defaults () {
        return {
            'newsletter_email': '',
            'newsletter_privacy': '',
            'csrf_token': '',
        };
    }

    initialize ( attributes, options ) {
        // @TODO: remove this check once all forms switched to new validator
        options = {...options, ...{validator: {}}};
        super.initialize(attributes, options);
        if (options.newsletter_privacy && !options.newsletter_privacy.required) {
            delete this.attributes.newsletter_privacy;
        }
        this.form
            .add('newsletter_email', [
                new NotBlank({message: 'Email address is required.'}),
                new Length({max: 150, message_max: 'Email is too long.'}),
                new Email({message: 'Email address is not valid.'}),
            ]);

        if (options.newsletter_privacy && options.newsletter_privacy.required) {
            this.form.add('newsletter_privacy', [
                new IsTrue({
                    message: 'You need to agree to the Privacy Policy.'
                })
            ]);
        }
    }
}
