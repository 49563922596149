export default class Manifest {
    constructor() {
        if (Manifest.instance) {
            return Manifest.instance;
        }

        Manifest.instance = this;
        this.data = this.load();

        return this;
    }

    /**
     * Fetch current asset bank
     * @returns {Promise}
     */
    load () {
        const host = window.location.origin;
        const time = new Date();

        return fetch(host + '/build/manifest.json?' + time.getTime(), {cache: 'reload'})
            .then((response) => {
                return response.json();
            }).catch((error) => {
                console.error(error);
            });
    }

    /**
     * Search for specific asset
     * @param {String} name
     * @returns {String}
     */
    async asset ( name ) {
        if (this.data instanceof Promise) {
            this.data = await this.data;
        }

        if (!this.data || !this.data[name]) {
            throw new Error('Asset ' + name + ' not found.');
        }

        return this.data[name];
    }
}
