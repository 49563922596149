const Routing = require('./../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js');

class Router {
    constructor() {
        if (Router.instance) {
            return Router.instance;
        }

        Router.instance = this;

        const data = window?.cryengine?.config?.routes || {};

        if (window) {
            data.host = window.location.host;
            data.scheme = window.location.protocol.replace(':', '');
        } else {
            data.host = '';
        }

        this.router = Routing;
        this.router.setRoutingData(data);

        return this;
    }

    /**
     * @returns {String}
     */
    generate(alias, params) {
        return Routing.generate(alias, params, true);
    }
}

export default function routeGenerate(alias, params) {
    const router = new Router();

    params = params || {};
    delete params['_keys'];

    return router.generate(alias, params);
}
