export default {
    /**
     * @param {VNode} oldVnode
     * @param {VNode} vnode
     */
    update: function ( oldVnode, vnode ) {

        if (oldVnode.elm.nodeName.toLowerCase() === 'div'
            && oldVnode.data && oldVnode.data.attrs
            && (oldVnode.data.attrs['data-bind'] === 'mt-captcha')
            && Array.isArray(oldVnode.children)
        ) {
            // do not delete initialized captcha
            oldVnode.children.forEach((childVNode, key) => {
                if (childVNode.data && childVNode.data.attrs ) {
                    vnode.children[key] = childVNode;
                }
            });
        }
    },
};
