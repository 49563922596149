/**
 * @TODO: 1. Think how to transfer validation rules from backend to frontend.
 *           General idea is to keep rules consistent to both sides.
 *        2. Localization
 *        3. Find other solution
 */
export default class AbstractAssert {
    /**
     * @param {{}} [options]
     */
    constructor ( options ) {
        this.options = {
            ...{
                required: false,
            },
            ...options,
        };
    }

    /**
     * @returns {string[]}
     */
    validate () {
        throw new Error('Validation rule was not implemented.');
    }

    /**
     * @param value
     * @param {Object} attributes
     * @returns {string[]|null}
     */
    isValid ( value, attributes ) {
        const errors = this.validate(value, this.options.required, attributes);
        const hasErrors = Array.isArray(errors) && errors.length > 0
                          || typeof errors === 'object' && Object.keys(errors).length > 0;

        return hasErrors ? errors : null;
    }

    /**
     * @param value
     * @return {Array|Object}
     */
    initErrorObject ( value ) {
        if (typeof value === 'object') {
            const errors = {};
            Object.keys(value).forEach(( key ) => {
                errors[key] = [];
            });

            return errors;
        } else {
            return [];
        }
    }
}
