import AbstractView from '../AbstractView';
import ReCaptcha from './Captcha/ReCaptcha';
import MtCaptcha from './Captcha/MtCaptcha';

const SELECTOR_RECAPTCHA_BLOCK = '[data-bind="g-recaptcha"]';
const SELECTOR_RECAPTCHA_CONTAINER = '[data-recaptcha-container]';

const SELECTOR_MTCAPTCHA_BLOCK = '[data-bind="mt-captcha"]';
const SELECTOR_MTCAPTCHA_CONTAINER = '[data-mt-container]';

// this class should trigger patch on captcha nodes
const CLASS_HIDDEN = 'visibility-hidden';

const FALLBACK_TIMEOUT = 1000;

export default class Captcha extends AbstractView {
    events() {
        return {
            'click [data-bind="g-recaptcha-reload"]': 'reload'
        };
    }

    initialize(options) {
        super.initialize(options);

        this.initialized = false;

        const reCaptchaEl = this.el.querySelector(SELECTOR_RECAPTCHA_BLOCK);
        const mtCaptchaEl = this.el.querySelector(SELECTOR_MTCAPTCHA_BLOCK);

        reCaptchaEl.classList.add(CLASS_HIDDEN);
        mtCaptchaEl.classList.add(CLASS_HIDDEN);

        if (reCaptchaEl) {
            reCaptchaEl.classList.remove(CLASS_HIDDEN);

            this.loadReCaptcha();
            setTimeout(() => {
                const containerEl = this.el.querySelector(SELECTOR_RECAPTCHA_CONTAINER);
                if (!containerEl) {
                    return;
                }

                if (typeof window.grecaptcha === 'undefined') {
                    console.log(this.constructor.name + '::initCaptcha - ReCaptcha timeout.');

                    reCaptchaEl.classList.add(CLASS_HIDDEN);
                    mtCaptchaEl.classList.remove(CLASS_HIDDEN);
                    this.loadMtCaptcha();
                }
            }, FALLBACK_TIMEOUT);
        } else if (mtCaptchaEl) {
            this.loadMtCaptcha();
        }
    }

    loadReCaptcha() {
        const cEl = this.el.querySelector(SELECTOR_RECAPTCHA_BLOCK);
        const containerEl = this.el.querySelector(SELECTOR_RECAPTCHA_CONTAINER);

        if (!containerEl) {
            console.log(this.constructor.name + '::initCaptcha - ReCaptcha container not found.');
            return;
        }

        this.gKey = cEl ? cEl.dataset.key : '';
        this.g = new ReCaptcha({key: this.gKey});
        this.g.on('token', token => {
            this.onToken(typeof token === 'string' && token.length > 0 ? `gc::${token}` : '');
        });
        this.g.on('initialized', () => this.initialized = true);
        this.g.load(containerEl);
    }

    loadMtCaptcha() {
        const cEl = this.el.querySelector(SELECTOR_MTCAPTCHA_BLOCK);
        const containerEl = this.el.querySelector(SELECTOR_MTCAPTCHA_CONTAINER);

        if (!containerEl) {
            console.log(this.constructor.name + '::initCaptcha - MTCaptcha container not found.');
            return;
        }

        this.mtKey = cEl ? cEl.dataset.key : '';
        this.m = new MtCaptcha({key: this.mtKey});
        this.m.on('token', (token, id) => {
            if (id === containerEl.getAttribute('id')) {
                this.onToken(typeof token === 'string' && token.length > 0 ? `mt::${token}` : '');
            }
        });
        this.m.on('initialized', () => this.initialized = true);
        this.m.load(containerEl);
        this.mtId = containerEl ? containerEl.getAttribute('id') : '';

        // disable recaptcha
        this.g = undefined;
    }

    onToken(token) {
        console.log(this.constructor.name + '::onCaptchaToken', this.el.dataset['bind']);
        this.trigger('token', token);
    }

    reload() {
        const reCaptchaEl = this.el.querySelector(SELECTOR_RECAPTCHA_BLOCK);

        if (this.g) {
            this.g.reload();
        }

        if (this.m) {
            reCaptchaEl.classList.add(CLASS_HIDDEN);
            this.m.reload();
        }
    }

    getMtId() {
        return this.mtId;
    }
}
