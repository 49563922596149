import AbstractFormView        from './AbstractFormView';
import PrivacyPreferencesModel from '../../Model/Form/PrivacyPreferencesModel';
import UserModel               from '../../Model/UserModel';

const CLASS_HIDDEN = 'visibility-hidden';
const REDIRECT_AFTER = 5000; // 5 seconds

export default class AbstractPrivacyPreferencesForm extends AbstractFormView {
    events () {
        return {
            ...this.bindModelView(new PrivacyPreferencesModel()),
            ...{
                'click [data-bind="accept-all"]': 'onAcceptAll',
                'click [data-bind="decline-all"]': 'onDeclineAll',
                'click [data-bind="save"]': 'onSave',
            },
        };
    }

    initialize ( options ) {
        options = {...{tpl: false, pwhLoader: true, hideOnSuccess: false}, ...options};

        super.initialize(options);

        this.user = new UserModel();
        this.hideOnSuccess = options.hideOnSuccess;

        this.tpl = options.tpl;
    }

    /**
     * @param {Event} e
     */
    async onAcceptAll ( e ) {
        console.log(this.constructor.name + '::onAcceptAll');

        window.mediator.trigger('track', 'accept', {
            event_category: 'privacy',
            event_label: 'all'
        });

        this.toggleAll(true);

        // load GA, because user confirmed to enable all cookies
        if (typeof window.__loadGA === 'function') {
            window.__loadGA();
        }

        this.onSave(e);
    }

    async onDeclineAll ( e ) {
        console.log(this.constructor.name + '::onDeclineAll');

        window.mediator.trigger('track', 'decline', {
            event_category: 'privacy',
            event_label: 'all'
        });

        this.toggleAll(false);

        this.onSave(e);
    }

    toggleAll ( state ) {
        this.el
            .querySelectorAll('input[type="radio"]')
            .forEach(( el ) => {
                el.checked = el.value === 1 ? state : !state;
            });

        this.model.set('basic', state ? 1 : 0);
        this.model.set('analytics', state ? 1 : 0);
        this.model.set('youtube', state ? 1 : 0);
        this.model.set('sketchfab', state ? 1 : 0);
        this.model.set('soundcloud', state ? 1 : 0);
    }

    async onSave ( e ) {
        if (this.hideOnSuccess) {
            this.el.classList.add(CLASS_HIDDEN);
        }

        await this.user.fetch();
        this.onSubmit(e);

        if (this.hideOnSuccess) {
            this.remove();
        }
    }

    onSuccess ( response ) {
        super.onSuccess(response);
        const redirect = this.model.get('redirect');

        if (!this.hideOnSuccess) {
            window.mediator.trigger('track', 'update', {
                event_category: 'privacy',
                event_label: 'settings',
            });

            window.mediator.trigger('privacy-preferences-saved', {basic: !!this.model.get('basic')});
            this.render({success: true, redirect: redirect});
        }


        if (redirect) {
            setTimeout(() => {
                window.location.href = redirect;
            }, REDIRECT_AFTER);
        }
    }
}
