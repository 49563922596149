export default {
    /**
     * @param {VNode} oldVnode
     * @param {VNode} vnode
     */
    update: function ( oldVnode, vnode ) {
        // do not patch inline styles for textarea with EDITOR feature
        if (oldVnode.elm.nodeName.toLowerCase() === 'textarea'
            && oldVnode.data && oldVnode.data.attrs
            && oldVnode.data.attrs['data-feature'] === 'editor'
        ) {
            if (oldVnode.data.attrs['style']) {
                vnode.data.attrs['style'] = oldVnode.data.attrs['style'];
                vnode.elm.setAttribute('style', oldVnode.data.attrs['style']);
            }

            if (oldVnode.data.attrs['ckeditor']) {
                vnode.data.attrs['ckeditor'] = oldVnode.data.attrs['ckeditor'];
                vnode.elm.setAttribute('ckeditor', oldVnode.data.attrs['ckeditor']);
            }

            vnode.children = []; // no child nodes for textarea
        }

        // do not delete initialized EDITOR
        if (Array.isArray(oldVnode.children)) {
            oldVnode.children.forEach((childVNode, key) => {
                if (childVNode.data && childVNode.data.attrs && childVNode.data.attrs.role === 'application') {
                    vnode.children.splice(key, 0, childVNode);
                }
            });
        }
    },
};
