import AbstractModal from './AbstractModal';
import AbstractModel from '../../Model/AbstractModel';

const LOADER_TPL = require('@component/modal/loader.twig');

export default class LoaderView extends AbstractModal {
    initialize ( options ) {
        super.initialize(options);

        options = {
            ...{
                title: '',
                desc: '',
            },
            ...options,
        };


        this.tpl = LOADER_TPL;
        this.init = this.initLoader(options.title, options.desc);
    }

    async initLoader ( title, desc, show ) {
        show = show ? 1 : 0;
        const div = document.createElement('div');
        div.innerHTML = await this.tpl();

        this.el = div.firstChild;
        this.body.append(div);
        this.model = new AbstractModel({
            title: title,
            desc: desc,
            show: show,
        });

        this.model.on('change', ( model ) => {
            console.log(this.constructor.name + '::onChange:', model);
            this.render(model.toJSON());
        });
    }

    async show () {
        await this.init;

        this.lockBody();
        this.model.set('show', 1);
    }

    async hide () {
        await this.init;

        this.model.set('show', 0);
        this.unlockBody();
    }
}
