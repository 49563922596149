import AbstractView from '../../AbstractView';

const RECAPTCHA_SCRIPT_URL = 'https://www.google.com/recaptcha/api.js?render=explicit&onload={cb}&hl=en';
const CALLBACK_LOAD = 'reCaptchaLoadResolve';

export default class ReCaptcha extends AbstractView {
    initialize ( options ) {
        super.initialize(options);

        this.key = options.key;
    }

    /**
     * @param {Element} container
     */
    load(container) {
        console.log(this.constructor.name + '::initRecaptcha');

        new Promise(resolve => {
            window[CALLBACK_LOAD] = () => resolve(true);

            if (typeof window.grecaptcha === 'undefined') {
                const rScript = document.createElement('script');
                rScript.setAttribute('defer', 'defer');
                rScript.setAttribute('async', 'async');

                rScript.src = RECAPTCHA_SCRIPT_URL.replace('{cb}', CALLBACK_LOAD);
                document.querySelector('body').appendChild(rScript);
            } else {
                resolve(true);
            }
        })
            .then(() => {
                try {
                    this.reCaptcha = window.grecaptcha.render(container, {
                        'sitekey': this.key,
                        'theme': 'dark',
                        'type': 'image',
                        'size': window.innerWidth < 360 ? 'compact' : 'normal',
                        'callback': ( token ) => {
                            this.trigger('token', token);
                        },
                        'expired-callback': () => {
                            this.trigger('token', '');
                        },
                    });
                } catch (e) {
                    console.log(e);
                }

                this.trigger('initialized');
            });
    }

    reload() {
        if (typeof this.reCaptcha === 'undefined' || typeof window.grecaptcha === 'undefined') {
            return;
        }

        window.grecaptcha.reset(this.reCaptcha);
        this.trigger('token', '');
        console.log(this.constructor.name + '::reloadRecaptcha');
    }
}
