import AbstractView from './AbstractView';
import BannerModel  from '../Model/BannerModel';

const CLASS_HIDDEN = 'is-hidden';

export default class BannerView extends AbstractView {
    events () {
        return {
            'click [data-bind="close"]': 'onClose'
        };
    }

    initialize () {
        console.log(this.constructor.name + '::initialize');

        this.model = new BannerModel();
    }

    onClose() {
        this.el.classList.add(CLASS_HIDDEN);

        try {
            this.model.save();
            setTimeout(() => {
                console.log(this.constructor.name + '::maintenance - closed');
                this.remove();
            });
        } catch (e) {
            console.error(e);
        }

    }
}
