import AbstractView           from './AbstractView';
import PrivacyPreferencesDefaultForm from './Form/PrivacyPreferencesForm';
import PrivacyPreferencesDashboardForm from './Form/Dashboard/PrivacyPreferencesForm';

const SELECTOR_FORM = '[data-bind="form-privacy-preferences"]';

export default class PrivacyPreferencesView extends AbstractView {
    events () {
        return {
            'click [data-bind="decline-all"]': 'onDeclineAll',
            'click [data-bind="save"]': 'onSave',
        };
    }

    initialize () {
        console.log(this.constructor.name + '::initialize');
        const options = {
            el: this.el.querySelector(SELECTOR_FORM),
            pwhLoader: true,
        };

        if (this.el.dataset.type === 'dashboard') {
            this.form = new PrivacyPreferencesDashboardForm(options);
        } else {
            this.form = new PrivacyPreferencesDefaultForm(options);
        }
    }

    onDeclineAll ( e ) {
        this.form.onDeclineAll(e);
    }

    onSave ( e ) {
        this.form.onSave(e);
    }
}
