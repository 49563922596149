import Backbone from '../Lib/Backbone';
import {Form, NotBlank, Type} from 'constraint-validator';
import Captcha from 'Lib/Constraints/Captcha';
import CsrfTokenAssert from '../Lib/Asserts/CsrfTokenAssert';
import ReCaptchaAssert from '../Lib/Asserts/ReCaptchaAssert';
import alias from '../Lib/Functions/alias';

function flatten(arr) {
    return arr.reduce(function (flat, toFlatten) {
        return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
    }, []);
}

export default class AbstractModel extends Backbone.Model {
    initialize(attributes, options) {
        this.asserts = {};
        this.options = {
            ...{
                ca_token: {
                    required: false,
                    active: true,
                }
            },
            ...options,
        };

        if (typeof this.options.validator !== 'undefined') {
            // @TODO: remove this check once all forms switched to new validator
            this.form = new Form(this.options.validator);

            // Configure default validation rules for specific fields
            Object.keys(this.attributes).forEach((field) => {
                switch (field) {
                    case 'csrf_token':
                        this.form.add(field, [
                            new NotBlank({
                                message: 'Security token has expired. Please try to submit again or refresh the page.'
                            }),
                            new Type({
                                type: 'string',
                                message: 'Security token has expired. Please try to submit again or refresh the page.'
                            }),
                        ]);
                        break;

                    case 'ca_token':
                        this.form.add(field, [
                            new Captcha(this.options.ca_token || {})
                        ]);
                        break;
                }
            });
        } else {
            Object.keys(this.attributes).forEach((field) => {
                this.asserts[field] = [];

                if (field === 'csrf_token') {
                    this.addAssert(field, new CsrfTokenAssert());
                }

                if (field === 'ca_token') {
                    this.addAssert(field, new ReCaptchaAssert(this.options.ca_token));
                }
            });
        }
    }

    addAssert(field, assert) {
        if (!Array.isArray(this.asserts[field])) {
            this.asserts[field] = [];
        }

        this.asserts[field].push(assert);
    }

    /**
     * @param {Object} attributes
     * @param {Object} option
     */
    validate(attributes= {}, option= undefined) {
        if (option && (!option.validate || option.xhr)) {
            // Skip validate by option or after server response
            return;
        }

        const validationErrors = {};

        if (this.form) {
            // @TODO: remove this check once all forms switched to new validator
            const data = this.toJSON();
            const errors = this.form.validate(data);

            Object.keys(errors).forEach(field => {
                const list = errors[field] instanceof Map
                    ? Array.from(errors[field].values())
                    : errors[field];

                if (Array.isArray(list)) {
                    errors[field] = list.map(map => {
                        if (map instanceof Map) {
                            const values = [];
                            Array.from(map.values()).forEach(element => {
                                values.push(element.value);
                            });

                            return values;
                        }

                        return map;
                    });

                    validationErrors[field] = flatten(errors[field])
                        .map(error => {
                            return error.message;
                        })
                        .filter((v, i, a) => a.indexOf(v) === i);
                }
            });

            if (Object.entries(validationErrors).length !== 0 && validationErrors.constructor === Object) {
                return validationErrors;
            }

            this.set(this.form.getData(), {silent: true});

            return;
        }

        Object.keys(attributes).forEach((field) => {
            const assertKey = field.replace(/\[.*\]/, '');
            let errors = [];
            let value;

            if (assertKey === field) {
                // common way for all properties
                value = attributes[field];

            } else {
                value = {};
                Object.keys(attributes).forEach((attrKey) => {
                    if (attrKey.indexOf(assertKey) === 0) {
                        value[attrKey] = attributes[attrKey];
                    }
                });
            }

            if (this.asserts[assertKey] && Array.isArray(this.asserts[assertKey])) {
                this.asserts[assertKey].forEach((assert) => {
                    /** @param AbstractAssert assert */
                    const error = assert.isValid(value, attributes);

                    if (error !== null) {
                        if (Array.isArray(error)) {
                            error.forEach(item => errors.push(item));
                        } else {
                            error[field].forEach(item => errors.push(item));
                        }
                    }
                });
            }

            if (Array.isArray(errors) && errors.length > 0) {
                validationErrors[field] = errors;
            }
        });

        if (Object.entries(validationErrors).length !== 0 && validationErrors.constructor === Object) {
            return validationErrors;
        }
    }

    reset(options) {
        this.validationErrors = {};

        const defaults = this.defaults();

        Object.keys(this.attributes).forEach((field) => {
            // Reset defined attributes
            if (field !== 'csrf_token') {
                this.set(field, defaults[field], options);
            }

            // remove attributes that were not defined
            if (typeof defaults[field] === 'undefined') {
                delete this.attributes[field];
            }
        });
    }

    makeAlias(from, to) {
        const slug = alias(this.get(from));

        this.set(to, slug);
    }
}
