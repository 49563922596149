import 'nodelist-foreach-polyfill';
import stickybits from 'stickybits';

import ConsoleLogs from './ConsoleLogs';
import NavView from './../View/NavView';
import PrivacyPreferencesSimpleForm from './../View/Form/PrivacyPreferencesSimpleForm';
import NewsletterForm from './../View/Form/NewsletterForm';
import LicenseAgreementModal from './../View/Modal/LicenseAgreementModal';
import GlobalSearchView from './../View/GlobalSearchView';
import AnalyticsView from './../View/AnalyticsView';
import LazyMediaView from './../View/LazyImagesView';
import UserModel from './../Model/UserModel';
import PaginationForm from './../View/Form/PaginationForm';
import PrivacyPreferencesView from './../View/PrivacyPreferencesView';
import BannerView from './../View/BannerView';
import is_launcher from './Functions/is_launcher';
import Dialog from 'Lib/Dialog';

const SELECTOR_LICENSE_MODAL = '[data-bind="license-modal"]';
const SELECTOR_HEADER_MENU = '[data-bind="global-header-menu"]';
const SELECTOR_HEADER_LIGHT = '[data-bind="global-header-light"]';
const SELECTOR_ROOT = '[data-bind="root"]';

export default class AbstractApplication {
    constructor() {
        try {
            new ConsoleLogs();
            new LazyMediaView({el: 'body'});
            new AnalyticsView({el: 'body'});
            new Dialog();

            const navEl = document.querySelector(SELECTOR_HEADER_MENU);
            const navLightEl = document.querySelector(SELECTOR_HEADER_LIGHT);

            if (navEl !== null || navLightEl != null) {
                new NavView({
                    el: navEl ? navEl : navLightEl,
                    light: !!navLightEl,
                });
            }

            // WARN: there is no z-index. do check before use
            // Known issue:
            // - intersections with sticky menu of CKEditor
            // - css sticky property has overflow hidden effect
            stickybits('[data-is-sticky]');

            this.initialize();
            this.loadViews(this.views());

            this.initUser();

            if (is_launcher()) {
                this.bindToIPC();
            }
        } catch (e) {
            console.error('Loading problem', e);
        }
    }

    views() {
        return {};
    }

    initialize() {
        console.log(this.constructor.name + '::initialize');
    }

    bindToIPC() {
        window.ipc = window.ipc || {sendToHost: () => {}};

        const sendToHost = window.ipc.sendToHost;
        window.ipc.sendToHost = function () {
            const payload = [...arguments];
            const name = payload.shift();

            console.log('IPC::call', name, payload);
            sendToHost.apply(null, arguments);
        };
    }

    /**
     * Load provied views if possible
     * @param views
     */
    loadViews(views = {}) {
        views = {
            ...{
                '[data-bind="banner-maintenance"]': BannerView,
                '[data-bind="global-header-menu"]': GlobalSearchView,
                [SELECTOR_LICENSE_MODAL]: LicenseAgreementModal,
                '[data-bind="form-privacy-preferences-simple"]': PrivacyPreferencesSimpleForm,
                '[data-bind="privacy-preferences"]': PrivacyPreferencesView,
                '[data-bind="form-newsletter"]': NewsletterForm,
                '[data-bind="form-pagination-mobile"]': PaginationForm,
            },
            ...views,
        };

        Object
            .keys(views)
            .forEach((selector) => {
                new Promise((resolve) => {
                    const els = document.querySelectorAll(selector);

                    els.forEach((el) => {
                        new views[selector]({el: el});
                    });

                    resolve();
                });
            });

        window.mediator.on('license-agreement-required', this.showLicenseModal, this);
    }

    initUser() {
        console.log('App::initUser - session');
        this.user = new UserModel({}, {session: true});
    }

    showLicenseModal() {
        console.log('App::showLicenseModal() - License agreement required');
        const modalEl = document.querySelector(SELECTOR_LICENSE_MODAL);

        if (modalEl) {
            return;
        }

        fetch('/api/user/license')
            .then(rsp => rsp.text())
            .then(modal => {
                const rootEl = document.querySelector(SELECTOR_ROOT);
                const tpl = document.createElement('template');

                // inject modal right after root element
                tpl.innerHTML = modal.trim();
                rootEl.parentNode.insertBefore(tpl.content.firstChild, rootEl.nextSibling);

                // bind form to just rendered content
                const modalEl = document.querySelector(SELECTOR_LICENSE_MODAL);

                if (modalEl) {
                    new LicenseAgreementModal({el: modalEl});
                }
            })
            .catch(e => {
                console.error(e);
            });
    }
}
