import AbstractModal from './AbstractModal';
import LicenseForm from '../Form/LicenseForm';

const SELECTOR_FORM = '[data-bind="form-license"]';

export default class LicenseAgreementModal extends AbstractModal {
    initialize(options = {}) {
        super.initialize(options);

        const formEl = document.querySelector(SELECTOR_FORM);

        if (formEl) {
            this.form = new LicenseForm({el: formEl});
            this.form.on('success', this.onSuccess.bind(this));

            // force body lock
            this.interval = setInterval(() => this.lockBody(), 500);
        }

        window.mediator.on('license-agreement-close', this.onSuccess, this);
    }

    onSuccess() {
        clearInterval(this.interval);
        this.unlockBody();
        this.remove();

        window.mediator.trigger('license-applied');
    }
}
