import {BroadcastChannel} from 'broadcast-channel';
import AbstractView from './AbstractView';
import UserModel    from '../Model/UserModel';

const SELECT_MESSAGES = '[data-bind="data-user-pm"]';

export default class NavView extends AbstractView {
    events () {
        return {};
    }

    initialize (options) {
        options = {...{light: false}, ...options};
        console.log(this.constructor.name + '::initialize', options);

        this.light = options.light;
        super.initialize(options);

        this.model = new UserModel();

        this.model.on('change:id', this.render, this);
        this.model.on('change:avatar', this.render, this);
        this.model.on('change:messages', this.updateMsgCounter, this);

        // just fetch first time user info
        this.model.fetch();

        this.bus = new BroadcastChannel('user');
        this.bus.onmessage = (msg) => {
            if (msg?.action === 'refresh') {
                this.model.fetch();
            }
        };
    }

    /**
     * Fetch header and footer for current user
     * @return {Promise<string | never>}
     */
    async template () {
        if (typeof this.request !== 'undefined') {
            return this.request;
        }

        this.request = fetch('/api/layout/blocks')
            .then(( rsp ) => {
                return rsp.json();
            })
            .then(( result ) => {
                return this.light ? result.light : result.header;
            })
            .catch(() => {
                console.error(this.constructor.name + '::render - unable to fetch menu data.');
                return '';
            })
            .finally(() => {
                this.request = undefined;
            });

        return this.request;
    }

    async render () {
        await super.render({});

        this.updateMsgCounter();
    }

    updateMsgCounter () {
        const el = this.el.querySelector(SELECT_MESSAGES);

        if (el !== null) {
            el.innerHTML = this.model.get('messages');
        }
    }
}
