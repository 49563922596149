import AbstractAssert from './../AbstractAssert';

const DIC_INVALID = 'Security token has expired. Please try to submit again or refresh the page.';

export default class CsrfTokenAssert extends AbstractAssert {
    /**
     * @param {String} value
     *
     * @returns {string[]}
     */
    validate ( value ) {
        return typeof value !== 'string' || !value ? [DIC_INVALID] : [];
    }
}
