import {Dialog as StyleGuideDialog} from '@cryengine/styleguide';

const old_alert = window.alert;
const old_confirm = window.confirm;
const old_prompt = window.prompt;

export default class Dialog {
    constructor() {
        new StyleGuideDialog({el: 'body'});

        if (typeof window.ce_dialog === 'undefined') {
            console.log('Dialog: switch "alert" to promise');
            window.alert = this.alert.bind(this);

            console.log('Dialog: switch "confirm" to promise');
            window.confirm = this.confirm.bind(this);

            console.log('Dialog: switch "prompt" to promise');
            window.prompt = this.prompt.bind(this);
        }
    }

    alert(msg) {
        console.log('Call to Promise::alert()');

        return new Promise(resolve => {
            old_alert(msg);
            resolve(true);
        });
    }

    confirm(msg) {
        console.log('Call to Promise::confirm()');

        return new Promise((resolve, reject) => {
            const result = old_confirm(msg);
            result ? resolve(true) : reject(false);
        });
    }

    prompt(msg) {
        return  new Promise(resolve => {
            const result = old_prompt(msg);
            resolve(result);
        });
    }
}
