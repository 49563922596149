import {init} from 'snabbdom/init';

import {classModule} from 'snabbdom/modules/class';
import {propsModule} from 'snabbdom/modules/props';
import {styleModule} from 'snabbdom/modules/style';
import {attributesModule} from 'snabbdom/modules/attributes';
import {datasetModule} from 'snabbdom/modules/dataset';
import editorModule from './IDOM/editor';
import captchaModule from './IDOM/captcha';
import {toVNode} from 'snabbdom/tovnode';

const patch = init([
    classModule,
    propsModule,
    styleModule,
    attributesModule,
    datasetModule,
    editorModule,
    captchaModule
]);

export default class IDOM {
    /**
     * @param {VNode} vNode
     * @param {Boolean} [patch]
     */
    fixVNodeSVGNs(vNode, patch) {
        patch = !!patch;

        if (vNode.elm.nodeName && vNode.elm.nodeName.toLowerCase() === 'svg') {
            patch = true;
        }

        if (patch && vNode.data) {
            vNode.data.ns = 'http://www.w3.org/2000/svg';
        }

        if (Array.isArray(vNode.children) && vNode.children && vNode.children.length > 0) {
            vNode.children.forEach((cvNode) => {
                this.fixVNodeSVGNs(cvNode, patch);
            });
        }
    }

    /**
     * Apply the HTML to element with IncrementalDOM
     *
     * @param {Element} el  - The element where to apply patch
     * @param {String} html - HTML that should be applied
     */
    patch ( el, html ) {
        const tpl = document.createElement('template');

        tpl.innerHTML = html.trim();

        const vNodeOld = el.elm ? el : toVNode(el);
        const vNodeNew = toVNode(tpl.content.firstChild);

        this.fixVNodeSVGNs(vNodeNew);

        return patch(vNodeOld, vNodeNew);
    }
}
