import Backbone from 'Lib/Backbone';

const SELECTOR_PAGE = '[data-gsv]';

export default class AnalyticsView extends Backbone.View {
    events () {
        return {
            'click [data-ge]': 'onTrack',
            'submit [data-bind="form-marketplace-search"]': 'onMpSearch',
            'submit [data-bind="form-learn-search"]': 'onLearnSearch',
            'submit [data-bind="form-global-search"]': 'onGlSearch',
            'submit [data-bind="form-news-search"]': 'onNewsSearch',
            'submit [data-bind="form-support-search"]': 'onSupportSearch',
            'submit [data-bind="form-tutorials-search"]': 'onTutorialsSearch',
        };
    }

    initialize () {
        window.mediator.on('track', this.track, this);

        if (window.IntersectionObserver) {
            this.initTrackPageEnd();
        }
    }

    initTrackPageEnd () {
        if (window.AObserver) {
            return;
        }

        window.AObserver = new IntersectionObserver(( entries, observer ) => {
            entries
                .forEach(entry => {
                    if (entry.isIntersecting) {
                        window.mediator.trigger('track', 'page_view', {
                            event_category: 'engagement',
                            event_label: entry.target.dataset.gsv,
                        });
                        observer.unobserve(entry.target);
                    }
                });
        }, {threshold: 0.20});

        document.querySelectorAll(SELECTOR_PAGE)
            .forEach(e => {
                window.AObserver.observe(e);
            });
    }

    onMpSearch ( e ) {
        this.onSubmitForm(e, 'search_marketplace', 'keyword');
    }

    onLearnSearch ( e ) {
        this.onSubmitForm(e, 'search_learn', 'keyword');
    }

    onNewsSearch ( e ) {
        this.onSubmitForm(e, 'search_news', 'q');
    }

    onSupportSearch ( e ) {
        this.onSubmitForm(e, 'search_support', 'q');
    }

    onTutorialsSearch ( e ) {
        this.onSubmitForm(e, 'search_tutorials', 'q');
    }

    onGlSearch ( e ) {
        this.onSubmitForm(e, 'search_global', 'q');
    }

    /**
     * @param {Event} e
     * @param {String} form - form name
     * @param {String} name - field name
     */
    onSubmitForm ( e, form, name ) {
        e.preventDefault();
        e.stopPropagation();

        const formEl = e.delegateTarget;
        const term = formEl.querySelector(`[name="${name}"]`).value;

        window.mediator.trigger('track', 'search', {event_category: 'search', search_term: term});
        window.mediator.trigger('track', 'search', {event_category: 'form', event_label: form});

        setTimeout(() => {
            formEl.submit(e);
        }, 150);
    }

    onTrack ( e ) {
        const event = e.delegateTarget.dataset.ge;
        const category = e.delegateTarget.dataset.gc || 'general';
        const label = e.delegateTarget.dataset.gl;

        const params = {
            event_category: category,
        };

        if (label) {
            params.event_label = label;
        }

        this.track(event, params);
    }

    track ( event, params ) {
        params = {...{}, ...params};
        console.log('Analytics::track', event, params);

        if (typeof window.gtag !== 'function') {
            window.dataLayer = window.dataLayer || [];
            window.gtag = function () {
                window.dataLayer.push(arguments);
            };
        }

        window.gtag('event', event, params);
    }
}
