import AbstractPrivacyPreferencesForm from './AbstractPrivacyPreferencesForm';
import UserModel                      from '../../Model/UserModel';

const TEMPLATE_FORM = require('@forms/privacy-preferences-simple-form.twig');

export default class PrivacyPreferencesSimpleForm extends AbstractPrivacyPreferencesForm {
    initialize ( options ) {
        options = {...options, ...{tpl: TEMPLATE_FORM, pwhLoader: false, hideOnSuccess: true}};

        super.initialize(options);

        this.user = new UserModel();
        this.user.on('change:id', this.onUserUpdate, this);
    }

    onUserUpdate() {
        if (this.user.get('id') > 0) {
            this.remove();
        }
    }
}
