export default class GlobalConfig {
    private static instance: GlobalConfig;

    options: object = {};

    private constructor() {
        this.options = window?.cryengine?.config || {};
    }

    public static getInstance(): GlobalConfig {
        if (!GlobalConfig.instance) {
            GlobalConfig.instance = new GlobalConfig();
        }

        return GlobalConfig.instance;
    }

    get(name: string, defaultValue: string | number | object | null = null): string | number | object | null {
        if (typeof this.options[name as keyof object] !== 'undefined') {
            return this.options[name as keyof object];
        } else {
            return defaultValue;
        }
    }

    param(name: string, defaultValue: string | number | object | null = null): string | number | object | null {
        const params = this.get('parameters', {});

        if (typeof params[name as keyof object] !== 'undefined') {
            return params[name as keyof object];
        } else {
            return defaultValue;
        }
    }
}
