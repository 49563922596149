import AbstractView from '../../AbstractView';
import rand_Id from '../../../Lib/Functions/rand_id';

export default class MtCaptcha extends AbstractView {
    constructor(options) {
        super(options);

        if (MtCaptcha.instance) {
            return MtCaptcha.instance;
        }

        MtCaptcha.instance = this;

        return this;
    }

    initialize ( options ) {
        super.initialize(options);

        this.key = options.key;
    }

    /**
     * @param {Element} container
     */
    load(container) {
        const mtId = 'MtCaptchaID' + rand_Id(7);
        console.log(this.constructor.name + '::initMtCaptcha - ID generated: ' + mtId);

        container.setAttribute('id', mtId);

        if (window.mtcaptchaConfig) {
            window.mtcaptchaConfig['renderQueue'].push(mtId);

            return;
        }

        const cbSId = 'mtCaptchaSuccessCallback';
        window[cbSId] = (status) => {
            console.log(this.constructor.name + `::${cbSId}`, status.domID);

            const token = window.mtcaptcha.getVerifiedToken(status.domID);
            this.trigger('token', token, status.domID);
        };

        const cbEId = 'mtCaptchaErrorCallback';
        window[cbEId] = (e) => {
            console.log(this.constructor.name + `::${cbSId}`, e);
            this.trigger('token', '');
        };

        const cbRId = 'mtCaptchaRenderedCallback';
        window[cbRId] = (e) => {
            console.log(this.constructor.name + `::${cbRId}`, e);
            this.trigger('initialized');
        };

        window.mtcaptchaConfig = {
            'sitekey': this.key,
            'theme': 'blackmoon',
            'render': 'explicit', // MUST be 'explicit' to prevent automatic rendering
            'renderQueue': [mtId],     // array needed for explicit rendering
            'rendered-callback': cbRId,
            'verified-callback': cbSId,
            'verifyexpired-callback': cbEId,
            'error-callback': cbEId,
            'customStyle': {
                'cardColor': '#222222',
                'cardShadowColor': 'rgba(0,0,0,0.2)',
                'cardBorder': '1px solid #525252',
                'placeHolderColor': '#4d4d4d',
                'inputTextColor': '#DDDDDD',
                'msgTextColor': '#999999',
                'invalidMsgTextColor': '#FF9913',
                'inputBackgroundColor': '#222222',
                'inputBorderColor': {
                    'byDefault': '#666666',
                    'hover': '#CCCCCC',
                    'active': '#00AEEF'
                },
                'buttonIconColor': {
                    'refresh': '#DDDDDD',
                    'verify': '#55CAF1',
                    'success': '#39c652',
                    'fail': '#FF9913',
                    'audio': '#DDDDDD',
                    'audiofocus': '#FFFFFF'
                }
            }
        };

        const mt_service1 = document.createElement('script');

        mt_service1.async = true;
        mt_service1.src = 'https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js';
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(mt_service1);

        const mt_service2 = document.createElement('script');
        mt_service2.async = true;
        mt_service2.src = 'https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js';
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(mt_service2);
    }

    reload() {
        if (!window.mtcaptcha) {
            return;
        }

        window.mtcaptcha.resetUI();
        this.trigger('token', '');
    }
}
