import AbstractFormView from './AbstractFormView';
import LicenseModel     from '../../Model/Form/LicenseModel';
import UserModel        from '../../Model/UserModel';

const TEMPLATE_FORM = require('@forms/license-form.twig');

/**
 * User login form
 */
export default class LicenseForm extends AbstractFormView {
    events () {
        return {
            ...this.bindModelView(new LicenseModel()),
            ...{},
        };
    }

    initialize ( options ) {
        options = {
            ...options,
            ...{
                pwhLoader: true
            }
        };

        super.initialize(options);

        this.user = new UserModel();
        this.tpl = TEMPLATE_FORM;
    }

    onSuccess ( response ) {
        this.user.fetch();
        super.onSuccess(response);
    }
}
