import AbstractModel from './AbstractModel';
import GlobalConfig from '@utils/GlobalConfig';
import is_launcher from 'Lib/Functions/is_launcher';

const Config = GlobalConfig.getInstance();
const STORAGE_KEY = 'ce-u';
const STORAGE_KEY_CART = 'mp-c';

const CHECK_INTERVAL = 1000 * 60 * 21;
// const LOGOUT_INTERVAL = 1000 * 5;

export default class UserModel extends AbstractModel {
    constructor(attributes, options) {
        if (UserModel.instance) {
            return UserModel.instance;
        }

        super(attributes, options);

        UserModel.instance = this;

        return this;
    }

    url() {
        return '/api/session/check';
    }

    defaults() {
        return {
            'id': -1,
            'username': '',
            'avatar': '',
            'messages': 0,
            'license': false,
        };
    }

    redirectToHome() {
        if (window.location.pathname === '/' || is_launcher()) {
            return;
        }

        //console.log(`User logged out. Redirect to home page in ${LOGOUT_INTERVAL / 1000}s`);
        // setTimeout(() => window.location.href = '/', LOGOUT_INTERVAL);
    }

    initialize(attributes, options) {
        attributes = {
            ...Config.get('user'),
            ...attributes
        };

        super.initialize(attributes, options);
        this.set(attributes);

        if (window && window.localStorage) {
            if (document.cookie.length === '') {
                window.localStorage.removeItem(STORAGE_KEY);
            }

            try {
                const stored = window.localStorage.getItem(STORAGE_KEY);
                const storedUser = JSON.parse(stored);

                if (storedUser && storedUser.id !== this.get('id')) {
                    this.saveToStorage(true);
                    window.localStorage.setItem(STORAGE_KEY_CART, JSON.stringify([]));

                    this.redirectToHome();
                }
            } catch (e) {
                console.error(e);
            }
        }

        window.mediator.on('user-state-updated', this.loadStoredUser, this);
        window.addEventListener('storage', this.onStorageEvent.bind(this), false);

        this.options = {
            ...{
                // enable session checks
                session: false
            },
            ...options
        };

        this.on('sync', this.saveToStorage, this);
        this.updateTimer();
    }

    updateTimer() {
        if (this.interval) {
            clearInterval(this.interval);
        }

        if (this.options.session) {
            this.interval = setInterval(() => {
                this
                    .fetch()
                    .catch(() => {
                        console.error('Probably user logged out.');
                        this.redirectToHome();
                    });
            }, CHECK_INTERVAL);
        }
    }

    loadStoredUser() {
        try {
            const user = window.localStorage.getItem(STORAGE_KEY);

            this.set(JSON.parse(user));

            if (this.get('id') > 0 && !this.get('license')) {
                window.mediator.trigger('license-agreement-required');
            } else {
                window.mediator.trigger('license-agreement-close');
            }
        } catch (e) {
            this.fetch();
        }
    }

    saveToStorage(force) {
        if (Object.keys(this.changed).length === 0 && !!force) {
            return;
        }

        console.log(this.constructor.name + '::saveToStorage');
        window.localStorage.setItem(STORAGE_KEY, JSON.stringify(this.toJSON()));

        if (this.get('id') === 0) {
            setTimeout(() => {
                window.localStorage.removeItem(STORAGE_KEY);
            }, 500);
        }

        window.mediator.trigger('user-state-updated');
    }

    /**
     * @param {StorageEvent} event
     */
    onStorageEvent(event) {
        if (event.key === STORAGE_KEY) {
            console.log(this.constructor.name + '::onStorageEvent', event);

            const oldValue = JSON.parse(event.oldValue);
            const newValue = JSON.parse(event.newValue);

            if (oldValue && newValue &&
                (oldValue?.id > 0 && newValue?.id === 0
                    || oldValue?.id > 0 && newValue?.id > 0 && oldValue?.id !== newValue?.id
                )
            ) {
                this.redirectToHome();
            }

            this.loadStoredUser();
        }
    }
}
