import AbstractView     from '../AbstractView';
import PaginationRouter from '../../Router/PaginationRouter';

/**
 * Process mobile pagination
 */
export default class PaginationForm extends AbstractView {
    events () {
        return {
            'change [name="page"]': 'onPaginate'
        };
    }

    initialize ( options ) {
        super.initialize(options);

        this.router = new PaginationRouter();
    }

    onPaginate(e) {
        e.preventDefault();

        let page = e.target.value;

        if (page.charAt(0) === '#') {
            page = page.replace('#', '');
            this.router.navigate(page, {trigger: true});
        } else {
            this.router.setQueryParam('page', e.target.value);
        }

        window.mediator.trigger('track', 'pagination', {
            event_category: 'engagement',
            event_label: page
        });
    }
}
