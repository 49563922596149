import AbstractPrivacyPreferencesForm from './AbstractPrivacyPreferencesForm';

const TEMPLATE_FORM = require('@forms/privacy-preferences-form.twig');

export default class PrivacyPreferencesForm extends AbstractPrivacyPreferencesForm {
    initialize ( options ) {
        options = {...options, ...{tpl: TEMPLATE_FORM}};

        super.initialize(options);
    }

    hideSuccess() {
        // just keep success
    }
}
