import AbstractView from '../AbstractView';

const CLASS_BODY_STICK = 'no-scroll';

export default class AbstractModal extends AbstractView {
    initialize ( options ) {
        console.log(this.constructor.name + '::initialize');
        super.initialize(options);

        this.body = document.querySelector('body');
    }

    lockBody () {
        if (!this.body.classList.contains(CLASS_BODY_STICK)) {
            console.log(this.constructor.name + '::lockBody()');
            this.body.classList.add(CLASS_BODY_STICK);
        }
    }

    unlockBody () {
        console.log(this.constructor.name + '::unlockBody()');
        this.body.classList.remove(CLASS_BODY_STICK);
    }
}
