import AbstractView from './../AbstractView';
import {Editor, DatePicker, Utils} from '@cryengine/styleguide';
import LoaderView   from '../Modal/LoaderView';
import rangePlugin  from 'flatpickr/dist/plugins/rangePlugin';
import { isObject } from 'constraint-validator/src/Utils/functions';
import Captcha      from './Captcha';
import GlobalConfig from '@utils/GlobalConfig';
import rand_Id from '../../Lib/Functions/rand_id';

const SELECTOR_ERROR_ELEMENT = '.fieldset--has-error';
const SELECTOR_ERROR_FORM_ELEMENT = '[data-bind="form-error-block"]';
const SELECTOR_FLASH_MSG = '[data-bind="flash-message"]';

const CLASS_HIDDEN = 'is-hidden';

const Config = GlobalConfig.getInstance();

export default class AbstractFormView extends AbstractView {
    initialize ( options ) {
        options = {
            ...{
                pwhLoader: false, // show loader before submit
                triggerSuccess: true,
                resetOnSuccess: false,
            },
            ...options,
        };

        super.initialize(options);
        console.log(this.constructor.name + '::initialize', options);

        if (this.el === null) {
            throw new Error(this.constructor.name + '::initialize - Form not found.');
        }

        this.triggerSuccess = options.triggerSuccess;
        this.resetOnSuccess = options.resetOnSuccess;
        this.formName = this.el.dataset.bind;

        if (options.pwhLoader) {
            this.loader = new LoaderView({
                'title': this.el.dataset['submitTitle'],
                'desc': this.el.dataset['submitDesc'],
            });
        }

        this.actionsTpl = options.actionsTpl;

        this.hideSuccess();
    }

    hideSuccess () {
        if (this.hideSuccessId) {
            clearTimeout(this.hideSuccessId);
        }

        this.hideSuccessId = setTimeout(() => {
            // hide success message after 5 seconds
            const fMsgEl = this.el.querySelector(SELECTOR_FLASH_MSG);
            if (fMsgEl) {
                fMsgEl.classList.add(CLASS_HIDDEN);
            }
        }, 5000);
    }

    /**
     * Generate list of events for Backbone view to bind model to view
     *
     * @param {AbstractModel|Backbone.Model} model
     * @param {{}} [options]
     * @returns {{}}
     */
    bindModelView ( model, options = {} ) {
        console.log(this.constructor.name + '::bindModelView', options);
        this.model = model;
        this.editors = this.editors || {};
        this.pickers = [];

        const events = {
            'submit': 'onSubmit',
            'click [data-bind="toggle-password-visibility"]': 'onTogglePassword',
        };

        Object.keys(model.attributes).forEach(( attr ) => {
            // build view events and initialize default values
            const inputEl = this.el.querySelector(`[name="${attr}"]`);
            const action = `change [name="${attr}"]`;

            if (attr !== 'ca_token' || attr === 'ca_token' && inputEl !== null) {
                this.updateModelValue(inputEl, {silent: true}, attr);
                events[action] = 'onInputChange';
            }

            if (attr === 'ca_token' && inputEl !== null) {
                if (typeof this.captcha === 'undefined' || !this.captcha.initialized) {
                    this.captcha = new Captcha({el: this.el});
                    this.mCaptchaId = this.captcha.getMtId();
                    this.captcha.on('token', token => this.model.set('ca_token', token, {silent: true}));
                } else {
                    this.captcha.reload();
                }
            }

            this.initFeatures(inputEl, options);
        });

        this.model.on('invalid', this.onValidationErrors.bind(this));
        this.model.on('sync', ( model ) => {
            this.render(model.toJSON());
        });

        return events;
    }

    /**
     * @param {HTMLElement} el
     * @param {Object} [options]
     */
    initFeatures ( el, options ) {
        if (el === null || !el || !el.dataset.feature) {
            return;
        }

        const feature = el.dataset.feature;

        options = {
            ...{
                features: {
                    editor: {},
                    datepicker: {},
                },
            },
            ...options,
        };

        // initialize
        switch (feature) {
            case 'editor':
                this.initEditor(el, {editor: options.features.editor});
                break;

            case 'datepicker':
                this.initDatePicker(el, options.features.datepicker);
                break;
        }
    }

    /**
     * @param {HTMLElement} el
     * @param options
     */
    initEditor ( el, options ) {
        console.log(this.constructor.name + '::initEditor', el, options);

        let editorId = el.getAttribute('ckeditor');

        const bindCounter = (editor) => {
            // render word count on changes
            if (editor.plugins.has( 'WordCount' )) {
                const plugin = editor.plugins.get( 'WordCount' );
                const wrpEl = editor.sourceElement.parentElement.closest('fieldset');

                if (wrpEl) {
                    const containerEl = wrpEl.querySelector('[data-bind="characters-count"]');
                    if (containerEl) {
                        containerEl.appendChild(plugin.wordCountContainer);
                    }
                }
            }
        };

        if (editorId !== null) {
            // already initialized
            const editorView = this.editors[editorId];
            const editor = editorView.getEditor();

            if (editor) {
                editor.updateSourceElement(el);
                bindCounter(editor);
            }
        } else {
            editorId = rand_Id(32);
            options.onReady = ( editor ) => {
                editor.model.document.on('change', Utils.debounce(() => {
                    el.value = editor.getData();

                    // hack: trigger to fetch new token
                    try {
                        if (editor.plugins.has('CloudServices')) {
                            editor.plugins.get('CloudServices')?.token?._refresh();
                        }
                    } catch (e) {
                        console.error(e);
                    }

                    this.updateModelValue(el);
                }, 500));

                bindCounter(editor);
            };

            el.setAttribute('ckeditor', editorId);
            this.editors[editorId] = new Editor(el, options);
        }
    }

    /**
     * @param {Element} el
     * @param {Object} options
     */
    initDatePicker ( el, options ) {
        const pOptions = {...options, ...{disableMobile: 'true'}};
        console.log(this.constructor.name + '::initDatePicker', el, pOptions);

        if (options.mode === 'range') {
            const toElSelector = el.getAttribute('name').replace(/_from$/, '_to');
            const toEl = this.el.querySelector(`[name="${toElSelector}"]`);
            const rPlugin = new rangePlugin({input: toEl});

            if (!Array.isArray(pOptions.plugins)) {
                pOptions.plugins = [];
            }

            // initialize range plugin
            pOptions.plugins.push(rPlugin);
            pOptions.onChange = () => {
                // todo: handle when onChange passed in configuration
                this.updateModelValue(toEl);
            };

            console.log(this.constructor.name + '::datepicker - range plugin', toElSelector);
        }


        const datepicker = new DatePicker(el, pOptions);
        //
        this.pickers.push(datepicker);
    }

    /**
     * @param {Event} e
     */
    onInputChange ( e ) {
        const el = e.target;
        this.updateModelValue(el);
    }

    beforeSubmit () {
        console.log(this.constructor.name + '::beforeSubmit');
        this.trigger('before-submit', this.model);
    }

    /**
     * @param {Event} e
     * @param {Object} [options]
     */
    onSubmit ( e, options ) {
        e.preventDefault();
        console.log(this.constructor.name + '::onSubmit');

        window.mediator.trigger('track', 'submit', {
            event_category: 'form',
            event_label: this.formName,
        });

        options = {
            ...{parse: true, patch: false},
            ...options,
        };

        if (this.loader) {
            this.loader.show();
        }

        this.model.once('request', this.beforeSubmit.bind(this));

        if (typeof this.collection !== 'undefined') {
            this.collection.add(this.model);
        }

        this.model.once('sync', ( model, rsp, options ) => {
            window.mediator.trigger('track', 'success', {
                event_category: 'form',
                event_label: this.formName
            });

            // sometimes model save it's not updating current model attributes.
            this.model.set(model.toJSON(), options);
            this.onSuccess(rsp);
        });

        const result = this.model.save(options.patch ? this.model.toJSON() : {}, options);

        if (result) {
            result
                .catch(( Error ) => {
                    window.mediator.trigger('track', 'errors', {
                        event_category: 'form',
                        event_label: this.formName
                    });

                    const rsp = Error.responseData;

                    if (rsp && rsp['csrf_token']) {
                        this.model.set('csrf_token', rsp['csrf_token']);
                    }

                    if (rsp && rsp.errors) {
                        this.onValidationErrors(this.model, rsp.errors);
                    } else if (rsp && rsp.message) {
                        this.onValidationErrors(this.model, {
                            'form': [rsp.message],
                        }, {});
                    } else {
                        console.error(Error);
                        this.onValidationErrors(this.model, {
                            'form': ['Server Internal Error'],
                        }, {});
                    }
                });
        }
    }

    showSuccess() {
        if (this.hideSuccessId) {
            clearTimeout(this.hideSuccessId);
        }

        const fMsgEl = this.el.querySelector(SELECTOR_FLASH_MSG);
        if (fMsgEl) {
            fMsgEl.classList.remove(CLASS_HIDDEN);
        }
    }

    async onSuccess ( response ) {
        console.log(this.constructor.name + '::onSuccess - Request completed successfully.', response);
        this.trigger('success', this.model);
        this.showSuccess();
        this.hideSuccess();

        if (this.resetOnSuccess) {
            await this.reset();
        }

        if (this.triggerSuccess) {
            await this.render({success: true});
        }
    }

    /**
     * @param {Element|EventTarget} el
     * @param {{}} [options]
     * @param {String} [attr]
     */
    updateModelValue ( el, options, attr ) {
        if (el === null) {
            // check case when we do not have only placeholder where form should be rendered later
            if (this.el.hasChildNodes()) {
                console.error('HTML form does not contains provided element.', attr);
            }

            return;
        }

        options = {
            ...{silent: true},
            ...options,
        };

        let type = el.getAttribute('type');
        const name = el.getAttribute('name');
        const radioEl = this.el.querySelector(`[name="${name}"]:checked`);

        let value = null;
        let setValue = true;

        const def = this.model.defaults()[name];

        if (Array.isArray(def)) {
            if (type !== 'checkbox') {
                try {
                    value = JSON.parse(el.value);
                } catch (e) {
                    value = [];
                }
            } else {
                // this is an array of values
                const selector = `[name="${name}"]:checked`;
                const els = this.el.querySelectorAll(selector);
                value = [];

                els.forEach(( el ) => {
                    if (el.checked) {
                        value.push(el.value);
                    }
                });
            }
        } else {
            type = type ? type : el.tagName.toLowerCase();

            const editorId = el.getAttribute('ckeditor');
            const editorView = this.editors[editorId];

            // process different types of inputs
            switch (type) {
                case 'checkbox':
                    value = !!el.checked;
                    break;

                case 'radio':
                    el = radioEl === null ? el : radioEl;
                    value = el.checked ? el.value : '';
                    setValue = !!el.checked;
                    break;

                case 'textarea':
                    // CRYCOM-2807: CKEditor has pure patch process with IDOM
                    if (editorView) {
                        value = editorView?.getData() || value;
                        el.value = value;
                    }

                    value = el.value;
                    break;

                default:
                    value = el.value;
            }
        }

        if (setValue) {
            if (name === this.model.idAttribute && !value) {
                value = undefined;
            }

            this.model.set(name, value, options);
            console.log('MU:', el, name, value, options);
        }
    }

    releaseMemory () {
        this.pickers.forEach(( picker ) => {
            picker.destroy();
        });

        this.pickers = [];
    }

    /**
     * @param data
     * @return {Promise<void>}
     */
    async render ( data ) {
        data = {
            ...this.model.toJSON(),
            ...{
                errors: this.model.validationError === null ? {} : this.model.validationError,
                global_m_id: this.mCaptchaId,
                global_g_key: Config.get('global_g_key'),
                global_m_key: Config.get('global_m_key'),
            },
            ...data,
            ...{
                show_captcha: data && !!data.show_captcha || this.captcha && this.captcha.initialized,
            },
        };

        this.convertDateObjects(data);
        this.releaseMemory();
        this.hideSuccess();

        const p = super.render(data);

        if (this.loader) {
            await p;
            this.loader.hide();
        }

        return p;
    }

    async onValidationErrors ( model, errors, options = {} ) {
        console.log(this.constructor.name + '::onValidationErrors', model, errors);

        this.model.set('ca_token', '', {silent: true});
        model.validationError = errors;

        const data = {
            ...model.toJSON(),
            ...options,
        };

        await this.render(data);
        this.trigger('validation-errors', model);
        this.scrollToError();
    }

    scrollToError () {
        // wait for editor initialization
        setTimeout(() => {
            // find first error and scroll to it
            const errEl = this.el.querySelector(SELECTOR_ERROR_ELEMENT);
            const errFormEl = this.el.querySelector(SELECTOR_ERROR_FORM_ELEMENT);

            if (errEl) {
                errEl.scrollIntoView({block: 'center', behavior: 'auto', inline: 'center'});
            } else if (errFormEl) {
                errFormEl.scrollIntoView({block: 'center', behavior: 'auto', inline: 'center'});
            }
        }, 500);
    }

    async reset (keepId = false) {
        console.log(this.constructor.name + '::reset()');

        // On destruction list of features can left artifacts, because they keep assigned values in custom structures
        this.releaseMemory();

        // keep form ID value
        const id = this.model.get(this.model.idAttribute);

        // Now reset form data and model
        this.model.reset();
        this.el.reset();

        if (keepId) {
            this.model.set(this.model.idAttribute, id, {silent: true});
        }

        await this.render();
    }

    /**
     * @param {Event} e
     */
    onTogglePassword ( e ) {
        const el = e.delegateTarget;

        const inputEl = this.el.querySelector(`input[name="${el.dataset.target}"]`);

        if (inputEl !== null) {
            const type = inputEl.getAttribute('type') === 'text' ? 'password' : 'text';

            inputEl.setAttribute('type', type);
        }
    }

    /**
     * Check each property of data object and convert to Date object
     *
     * @param data
     */
    convertDateObjects ( data ) {
        Object.keys(data).forEach(key => {
            let value = data[key];

            if (isObject(value) && typeof value.date !== 'undefined'  ) {
                value = new Date(data[key].date);
                if (value instanceof Date && !isNaN(value)) {
                    data[key] = value;
                }
            }
        });

        return data;
    }

    /**
     * Check keyboard event was triggered by Enter key
     *
     * @param {KeyboardEvent} e
     * @return {boolean}
     */
    isEnterKey(e) {
        if (!e) {
            return false;
        }

        return e.key === 'Enter' || e.keyCode === 13 || e.which === 13;
    }
}
